<template>
  <v-card
    v-bind="$attrs"
  >
    <v-card-title
      class="branded py-0 mb-n1"
      :class="{'px-0': fullWidth}"
    >
      {{ splitName[1] }}
      <v-img
        class="ms-1"
        src="/img/arkscore-logo-name.svg"
        max-width="80"
        height="10"
        contain
        alt="ArkScore"
      />
      {{ splitName[3] }}
      <v-spacer />
      <!-- <v-tooltip
        open-on-click
        left
        top
      >
        <template #activator="{ on, attrs }">
        </template>
        <span>{{ blurb }}</span>
      </v-tooltip> -->

      <v-dialog
        v-model="dialog"
        max-width="500"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small>
              fal fa-question-circle
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            {{ name }}
            <v-spacer />
            <v-btn
              icon
              tile
              color="grey"
              @click.stop="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            {{ blurb }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-card-text :class="{'px-0': fullWidth}">
      <arkscore-bar
        :key="`arkscore-bar-${score}`"
        :score="score"
        :height="barHeight"
      />
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {
    ArkscoreBar: () => import('@/components/arkscore/ArkscoreBar.vue'),
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    blurb: {
      type: String,
      default: null,
    },
    score: {
      type: Number,
      default: 1,
    },
    barHeight:{
      type: Number,
      default: 18,
    },
    fullWidth: Boolean,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    splitName() {
      return this.name.split(/(.*)(Arkscore)(.*)/)
    },
  },
}
</script>
<style lang="scss" scoped>
.branded {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.9em;
}
</style>
